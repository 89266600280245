// 引入 axios
import axios from "axios";



// export const base = 'http://localhost:9090';
export const base = 'http://8.140.206.29:9090';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}

//传送json格式的get请求
export const getList=(url,param)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        params: param
    })
}
